<template>
  <div class="line-progress">
    <div :class="['line-progress__line', mod]" :style="'width: ' + percent + '%'"></div>
  </div>
</template>

<script>
  export default {
    name: 'Line',
    props: {
      percent: {
        type: Number || String,
        default: 0,
      },
      mod: {
        type: String,
        validator(value) {
          return ['green'].includes(value);
        },
      },
    },
  };
</script>

<style scoped lang="scss">
  .line-progress {
    width: 100%;
    height: 4px;
    border-radius: 5px;
    background: $light-grey-10;
    position: relative;

    &__line {
      height: 4px;
      position: absolute;
      background: $blue;
      border-radius: 5px;

      &.green {
        background-color: $green;
      }
    }
  }
</style>
