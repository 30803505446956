<template>
  <div class="container-page big">
    <div class="title-block-top title-block-top--flag">
      <h2 class="title title--h2 title--cross-cutting">Сквозные задачи</h2>
      <div class="text-dot">{{ getNameCountry }}</div>
      <div class="country-flag">
        <img :src="getImageCountry" alt="country-flag" width="45" height="30" />
      </div>
    </div>

    <div class="info-block-country-task">
      <div class="info-block-country-task__item">
        <div class="info-block-country-task__title">{{ totalTasks }}</div>
        <div class="info-block-country-task__text">Всего сквозных задач</div>
      </div>
      <div class="info-block-country-task__item">
        <div class="info-block-country-task__title">{{ getTotalSubtasksTasks }}</div>
        <div class="info-block-country-task__text">Подзадач</div>
      </div>
      <div class="info-block-country-task__item info-block-country-task__item--big">
        <div class="info-block-country-task__item-inner">
          <div class="info-block-country-task__title green">{{ totalCompletedSubtasks }}</div>
          <div class="info-block-country-task__text info-block-country-task__text--narrow">Подзадач реализовано</div>
        </div>
        <div class="info-block-country-task__item-inner">
          <div class="info-block-country-task__title gray">{{ totalUncompletedSubtasks }}</div>
          <div class="info-block-country-task__text info-block-country-task__text--narrow">
            Подзадач не&nbsp;реализовано
          </div>
        </div>
        <div class="info-block-country-task__line-progress-all">
          <Line :percent="totalKpiPercent" mod="green" />
        </div>
      </div>
      <div class="info-block-country-task__item info-block-country-task__item--average">
        <div class="info-block-country-task__title">{{ totalKpiPercent }}%</div>
        <div class="info-block-country-task__text">KPI реализации сквозных задач</div>
      </div>
    </div>

    <div class="table-block">
      <div class="table-block__header">
        <div class="table-block__tr">
          <div class="table-block__th">Сквозная задача</div>
          <div class="table-block__th">Количество реализованных подзадач / Всего подзадач</div>
          <div class="table-block__th">% выполнения</div>
        </div>
      </div>
      <div class="table-block__body">
        <div
          class="table-block__tr"
          v-for="(item, index) in array"
          :key="index"
          :id="item.id"
          @click="nextPage(item.id)"
        >
          <div class="table-block__td">{{ item.name }}</div>
          <div class="table-block__td">
            <div :class="['table-block__top', { done: getDone(item) }]">
              <div class="table-block__difference">{{ item.countCompletedSubtasks }}/{{ getAllTask(item) }}</div>
              <div class="table-block__percent desktop-hidden">{{ item.completionPercentage }}%</div>
            </div>
            <div class="table-block__line-progress">
              <Line :percent="item.completionPercentage" :mod="getDone(item) ? 'green' : ''" />
            </div>
          </div>
          <div class="table-block__td">
            <div :class="['table-block__percent', { done: getDone(item) }]">{{ item.completionPercentage }}%</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';

  import Line from '@/modules/crossCuttingTask/components/Line';

  import API from '../api';

  export default {
    name: 'PageCrossCuttingTask',
    components: { Line },
    mixins: [security],
    data() {
      return {
        countryId: 0,
        totalTasks: 0,
        totalCompletedSubtasks: 0,
        totalUncompletedSubtasks: 0,
        totalKpiPercent: 0,
        array: [],
        currentCountry: {},
      };
    },
    created() {
      this.checkPermission(Constants.Permissions.AccessCrossCuttingTask).then((response) => {
        if (response.data) {
          this.countryId = this.$route?.params?.id || 0;
          API.getInfoDetailPageCrossCuttingTask({ countryId: this.countryId }).then((response) => {
            if (response?.data) {
              const {
                totalTasks = 0,
                totalCompletedSubtasks = 0,
                totalUncompletedSubtasks = 0,
                totalKpiPercent = 0,
                taskCountSubtasks = [],
                country = {},
              } = response.data;
              this.totalTasks = totalTasks;
              this.totalCompletedSubtasks = totalCompletedSubtasks;
              this.totalUncompletedSubtasks = totalUncompletedSubtasks;
              this.totalKpiPercent = totalKpiPercent;
              this.array = taskCountSubtasks;
              this.currentCountry = country;
            }
          });
        } else this.notAccess();
      });
    },
    computed: {
      getNameCountry() {
        return this.currentCountry?.name || '';
      },

      getImageCountry() {
        return this.currentCountry?.picture || '';
      },

      getTotalSubtasksTasks() {
        let total = 0;
        this.array.forEach((i) => {
          total += i.countCompletedSubtasks + i.countUncompletedSubtasks;
        });
        return total;
      },
    },
    methods: {
      getDone(item) {
        return !item?.countUncompletedSubtasks;
      },
      getAllTask(item) {
        const { countCompletedSubtasks = 0, countUncompletedSubtasks = 0 } = item;
        return +countCompletedSubtasks + +countUncompletedSubtasks;
      },
      nextPage(id) {
        this.$router.push({ name: 'cross-cutting-task-card', params: { id: this.countryId, task: id } });
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/theme/default/title.scss';

  .country-flag {
    width: 45px;
    height: 30px;
    vertical-align: middle;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @media (max-width: $laptop) {
      width: 37px;
      height: 24px;
    }
  }

  .text-dot {
    font: 700 20px / 30px 'Fira Sans';
    text-transform: uppercase;
    position: relative;
    padding-left: 16px;
    margin-right: 15px;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 8px;
      height: 8px;
      background: $base;
      border-radius: 50%;
    }

    @media (max-width: $laptop) {
      margin-right: 8px;
    }
  }

  .info-block-country-task {
    display: flex;
    flex-wrap: wrap;
    color: $base;
    margin: 0 -8px 40px;

    &__item {
      border-radius: 12px;
      background: $light-grey-3;
      padding: 16px 24px;
      margin: 0 8px;
      width: calc(17% - 16px);
      min-height: 127px;

      &--average {
        width: calc(25% - 16px);
      }

      &--big {
        width: calc(41% - 16px);
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
      }

      &-inner {
        width: 50%;
      }
    }

    &__title {
      color: $blue;
      font: $fira-32-M;
      margin-bottom: 4px;

      &.green {
        color: $green;
      }
    }

    &__text {
      font: $fira-14;
      color: inherit;

      &--narrow {
        max-width: 50%;
      }
    }

    &__line-progress-all {
      width: 100%;
      margin-top: 16px;
    }

    @media (max-width: $tablet) {
      &__item {
        width: calc(25% - 16px);
        order: 1;
        margin-bottom: 18px;

        &--average {
          width: calc(50% - 16px);
          overflow: 1;
        }

        &--big {
          width: calc(75% - 16px);
          order: 3;
          margin-bottom: 0;
        }
      }
    }

    @media (max-width: $laptop) {
      margin-bottom: 0;
      &__item {
        width: calc(50% - 16px);
        margin-bottom: 16px;
        padding: 8px 16px;

        &--average {
          width: calc(100% - 16px);
        }

        &--big {
          width: calc(100% - 16px);
          margin-bottom: 0;
        }
      }

      &__title {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }

  .table-block {
    color: $base;
    &__header {
      border-bottom: 2px solid $green;
      padding-bottom: 12px;
    }

    &__tr {
      display: flex;
      align-items: flex-start;
    }

    &__th,
    &__td {
      width: 42%;

      &:first-child {
        width: 34%;
        padding-right: 5px;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &:last-child {
        width: 24%;
        padding-left: 70px;
      }
    }

    &__body .table-block__tr {
      padding: 32px 0;
      border-bottom: 1px solid $light-grey-11;
      cursor: pointer;
      transition: 0.3s ease;

      &:hover {
        opacity: 0.8;
      }
    }

    &__th {
      color: $dark-grey-1;
      font: 500 14px / 20px 'Fira Sans';
    }

    &__td {
      font: $fira-16;
      color: inherit;
    }

    &__percent,
    &__difference {
      color: $blue;
      font: $fira-20-M;

      .done & {
        color: $green;
      }
    }

    &__top {
      margin-bottom: 8px;
    }

    &__line-progress {
      width: 416px;
      max-width: 100%;
    }

    .done {
      color: $green;
    }

    .desktop-hidden {
      display: none;
    }

    @media (max-width: $tablet) {
      &__th,
      &__td {
        width: calc(100% / 3);

        &:first-child {
          width: calc(100% / 3);
        }
        &:last-child {
          width: calc(100% / 3);
        }
      }
    }

    @media (max-width: $laptop) {
      &__header {
        display: none;
      }

      &__body .table-block__tr {
        flex-wrap: wrap;
        padding: 24px 0;
      }

      &__td {
        width: 100%;

        &:first-child {
          width: 100%;
          margin-bottom: 16px;
        }
        &:last-child {
          display: none;
        }
      }

      &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .desktop-hidden {
        display: block;
      }
    }
  }
</style>
